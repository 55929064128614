import React from 'react';
import Grid from '@/components/modules/Grid';
import ModuleWrapper from '@/components/modules/ModuleWrapper/ModuleWrapper';
import SectionRichText from '../SectionRichText';
import type { GridSectionTypes } from './GridSection.types';
import * as styles from './GridSection.module.scss';

export default function GridSection({
  background,
  eyebrow,
  body,
  grid,
  items_in_row = 4,
  align = 'center',
  top_spacing = 'md',
  bottom_spacing = 'md',
}: GridSectionTypes) {
  const castedBackground = String(background);

  return (
    <section className={styles.wrapper} data-background={castedBackground}>
      <ModuleWrapper topSpacing={top_spacing} bottomSpacing={bottom_spacing}>
        <div className={styles.content} data-background={castedBackground}>
          {eyebrow && <p className={styles.eyebrow}>{eyebrow}</p>}
          {body && <SectionRichText body={body} className={styles} />}
          <Grid grid_elements={grid} items_in_row={items_in_row} align={align} />
        </div>
      </ModuleWrapper>
    </section>
  );
}
